import { Controller } from '@hotwired/stimulus';
import Carousel from 'bootstrap/js/dist/carousel';
import $ from 'jquery';
import { FormValidator } from '../../shared/form-validator';
import { disableButton, isDebugMode, isEditPage } from '../../shared/util';

export default class extends Controller {
  static targets = ['form', 'carousel', 'controls'];

  static values = { step: Number };

  initialize() {
    if (this.element.getAttribute('data-controller').includes('invoice')) {
      document.addEventListener('line-item:saved', () => {
        if (this.hasControlsTarget) {
          this.controlsTarget.classList.remove('d-none');
        }
      });
      document.addEventListener('line-item:removed', () => {
        if (this.hasControlsTarget) {
          this.controlsTarget.classList.remove('d-none');
        }
      });
      document.addEventListener('line-item:expanded', () => {
        if (this.hasControlsTarget) {
          this.controlsTarget.classList.add('d-none');
        }
        const newLineItemBtn = this.element.querySelector('.js-invoice-line-item-new-btn');
        $(newLineItemBtn).hide();
      });
      document.addEventListener('invoice:lineItemAdded', () => {
        if (this.hasControlsTarget) {
          this.controlsTarget.classList.add('d-none');
        }
      });
    } else {
      document.addEventListener('vehicle-lookup:resultRetrieved', () => {
        if (this.hasControlsTarget) {
          this.controlsTarget.classList.remove('d-none');
        }
      });
    }

    document.addEventListener('vehicle-lookup:error', () => {
      if (this.hasControlsTarget) {
        this.controlsTarget.classList.add('d-none');
      }
    });
  }

  connect() {
    if (this.hasCarouselTarget) {
      this.carousel = new Carousel(this.carouselTarget, {
        interval: false,
        keyboard: false,
        ride: false,
        wrap: false,
        touch: false,
      });
    }

    document.addEventListener('vehicle-lookup:resultRetrieved', (e) => {
      this.displayForm();
    });

    if (this.element.hasAttribute('data-client-side-validations')) {
      this.initValidation();
    }
    this.toggleProgressSteps();
    this.setupEventListeners();
  }

  initValidation() {
    setTimeout(() => {
      $(this.element).disableClientSideValidations();
    }, 200);
    setTimeout(() => {
      this.validators = this.element.ClientSideValidations.settings.validators;
    }, 500);
  }

  setupEventListeners() {
    document.addEventListener('keydown', (e) => {
      const isWithinTextEditor = e.target.closest('[data-controller="suneditor"]');
      const isWithinTextarea = e.target.nodeName === 'TEXTAREA';
      if (isWithinTextEditor || isWithinTextarea) {
        return true;
      }

      if (e.key === 'Enter') {
        const isPrevBtn = e.target.dataset.action === 'click->wizard#prevStep';
        const isNextBtn = e.target.dataset.action === 'click->wizard#nextStep';
        const isSubmitBtn = e.target.dataset.action === 'click->wizard#submitForm';

        if (!isSubmitBtn) {
          e.preventDefault();
        }

        if (isPrevBtn) {
          this.prevStep();
        }

        if (isNextBtn) {
          this.nextStep();
        }
      }
    });

    [...this.element.querySelectorAll('.form-check-input.needs-validation')].forEach((checkbox) => {
      checkbox.addEventListener('change', (e) => {
        e.target.classList.toggle('is-invalid', !e.target.checked);
      });
    });

    // if there is any change to form field & current step is marked as complete,
    // validate this step again to make sure all fields are still valid after a change
    [...this.element.querySelectorAll('.form-control, .form-select')].forEach((input) => {
      input.addEventListener('change', (e) => {
        const thisStep = e.target.closest('[data-step]');
        if (thisStep && thisStep.classList.contains('form-step-complete')) {
          this.validateCurrentStep();
        }
      });
    });

    // reset invalid class for form check panels (e.g. vehicle category)
    [...this.element.querySelectorAll('[data-form-check-panels-wrapper] input')].forEach(
      (radioTolbar) => {
        radioTolbar.addEventListener('change', (e) => {
          e.target.closest('[data-form-check-panels-wrapper]').classList.remove('is-invalid');
        });
      }
    );

    const progressStepsWrapper = document.querySelector('.progress-steps');
    if (progressStepsWrapper) {
      progressStepsWrapper.addEventListener('click', (e) => {
        this.handleStepClick(e);
      });
    }

    // this.carouselTarget.addEventListener('slide.bs.carousel', (e) => {
    //   this.animateCarouselHeight(e);
    // });
  }

  isFirstStep() {
    return this.stepValue === 1;
  }

  isLastStep() {
    const numberOfSteps = this.element.querySelectorAll('[data-step]').length;
    return this.stepValue === numberOfSteps;
  }

  displayForm() {
    $(this.formTarget).slideDown();
  }

  toggleProgressSteps() {
    const progressSteps = document.querySelectorAll('.progress-steps-step[data-step]');

    if (progressSteps.length) {
      progressSteps.forEach((step) => {
        const progressStep = step;
        progressStep.classList.remove('active', 'complete');

        const progressDataStep = parseInt(progressStep.dataset.step, 10);
        if (progressDataStep < this.stepValue) {
          progressStep.classList.add('complete');
        } else if (progressDataStep === this.stepValue) {
          progressStep.classList.add('active');
        }

        const correspondingFormStep = document.querySelector(
          `.form-step[data-step="${progressDataStep}"]`
        );
        const unlockProgressStep =
          isEditPage ||
          correspondingFormStep.classList.contains('form-step-started') ||
          correspondingFormStep.classList.contains('form-step-complete');
        if (unlockProgressStep) {
          progressStep.setAttribute('role', 'button');
        } else {
          progressStep.removeAttribute('role');
        }
      });
    }
  }

  handleStepClick(e) {
    const progressStep = e.target.closest('.progress-steps-step');
    if (progressStep) {
      const progressDataStep = parseInt(progressStep.dataset.step, 10);
      this.anyStep(progressDataStep);
    }
  }

  prevStep() {
    const prevStep = this.element.querySelector(`[data-step="${this.stepValue - 1}"]`);
    this.stepTo('prev');
    prevStep.classList.add('form-step-started');
  }

  nextStep(e) {
    const currentStep = this.element.querySelector(`[data-step="${this.stepValue}"]`);
    const nextStep = this.element.querySelector(`[data-step="${this.stepValue + 1}"]`);

    const { skipValidation } = e.params;
    const isThisStepValid = skipValidation ? true : this.validateCurrentStep();
    if (isThisStepValid) {
      currentStep.classList.remove('has-error');
      currentStep.classList.remove('form-step-started');
      currentStep.classList.add('form-step-complete');
      nextStep.classList.add('form-step-started');
      this.stepTo('next');
    } else {
      currentStep.classList.add('has-error');
      currentStep.classList.remove('form-step-complete');
    }
  }

  anyStep(requestedStepNumber) {
    if (isDebugMode()) {
      this.stepTo(requestedStepNumber);
      return;
    }

    const currentStep = this.element.querySelector(`[data-step="${this.stepValue}"]`);
    const requestedStep = this.element.querySelector(`[data-step="${requestedStepNumber}"]`);

    const shouldValidateCurrentStep = requestedStepNumber > this.stepValue;
    if (
      isEditPage ||
      requestedStep.classList.contains('form-step-started') ||
      requestedStep.classList.contains('form-step-complete')
    ) {
      if (shouldValidateCurrentStep && this.validateCurrentStep()) {
        currentStep.classList.remove('has-error');
        currentStep.classList.add('form-step-complete');
        requestedStep.classList.add('form-step-started');
        this.stepTo(requestedStepNumber);
      } else if (shouldValidateCurrentStep && !this.validateCurrentStep()) {
        currentStep.classList.add('has-error');
        currentStep.classList.remove('form-step-complete');
      } else {
        this.stepTo(requestedStepNumber);
      }
    }
  }

  stepTo(requestedStepNumber) {
    switch (true) {
      case Number.isInteger(requestedStepNumber):
        this.stepValue = requestedStepNumber;
        break;
      case requestedStepNumber === 'prev':
        // $(this.element).disableClientSideValidations();
        this.stepValue -= 1;
        break;
      case requestedStepNumber === 'next':
        // $(this.element).disableClientSideValidations();
        this.stepValue += 1;
        break;
      default:
        break;
    }

    this.showStep(this.stepValue);
    this.toggleProgressSteps();
  }

  showStep(stepToShowId) {
    [...this.element.querySelectorAll('[data-step]')].forEach((step) => {
      step.classList.remove('form-step-visible');
    });

    // show requested step
    this.carousel.to(stepToShowId - 1);
    const stepToShow = this.element.querySelector(`[data-step="${stepToShowId}"]`);
    stepToShow.classList.add('form-step-visible', 'form-step-started');
  }

  validateCurrentStep() {
    const currentStep = this.element.querySelector(`[data-step="${this.stepValue}"]`);
    const isThisStepValid = FormValidator.validate(currentStep, this.validators);
    // this.animateStepHeight(currentStep);
    return isThisStepValid;
  }

  submitForm(e) {
    if (e) {
      e.preventDefault();
    }
    let isFormValid = true;

    const isThisStepValid = this.validateCurrentStep();
    if (isThisStepValid) {
      const allSteps = this.element.querySelectorAll('[data-step]');
      const anyStepHasError = [...allSteps].some((step) => step.classList.contains('has-error'));

      if (anyStepHasError) {
        isFormValid = false;
      }

      if (isFormValid) {
        if (e) {
          const submitBtn = e.target;
          if (submitBtn) {
            disableButton(submitBtn);
          }
          e.target.closest('form').submit();
        } else {
          this.element.closest('form').submit();
        }
      }
    }
  }

  // animateCarouselHeight(e) {
  //   const carouselInner = $(this.carouselTarget)
  //     .find('.active')
  //     .parent('.carousel-inner');
  //   const carouselInnerVerticalPadding =
  //     parseFloat(carouselInner.css('padding-top').split('px')[0]) +
  //     parseFloat(carouselInner.css('padding-bottom').split('px')[0]);
  //   const nextH = $(e.relatedTarget).height() + carouselInnerVerticalPadding;
  //   carouselInner.animate({ minHeight: nextH }, 300);
  // }
}
