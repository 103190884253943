import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import { convertReg } from '../../shared/util';
import { apiClient } from '../../shared/apiClient';

export default class extends Controller {
  static targets = [
    'form',
    'vrmInput',
    'mileageInput',
    'submit',
    'loader',
    'error',
    'warningsContainer',
    'output',
    'vrmOutput',
    'makeLogoOutput',
    'makeModelOutput',
    'descriptionOutput',
    'retryButton',
    'existingVehicleLookup',
    'autocomplete',
    'lookupForm',
  ];

  initialize() {
    const urlParams = new URLSearchParams(window.location.search);
    this.vrm = urlParams.get('vrm');
    this.mileage = urlParams.get('mileage');

    const vehicleLookups = document.querySelectorAll(`[data-controller=${this.identifier}]`);
    this.isInitialLookup = vehicleLookups.length === 1;

    if (this.vrm && this.mileage && this.isInitialLookup) {
      // for initial lookup (on page load) show loader if VRM & mileage are present
      this.loaderTarget.classList.remove('d-none');
      if (this.hasFormTarget) {
        this.formTarget.classList.add('d-none');
      }
    }
  }

  async connect() {
    // only run lookup onload for first controller (if VRM & mileage are present) as new lookups will be done manually
    if (this.isInitialLookup && this.vrm && this.mileage) {
      if (this.hasVrmInputTarget && this.hasMileageInputTarget) {
        this.vrmInputTarget.value = this.vrm;
        this.mileageInputTarget.value = this.mileage;
      }

      if (this.hasExistingVehicleLookupTarget) {
        this.existingVehicleLookupTarget.classList.add('d-none');
      }
      try {
        const lookupResult = await this.getVehicleData(this.vrm, this.mileage);
        this.loaderTarget.classList.add('d-none');
        this.outputTarget.classList.remove('d-none');
        if (this.hasFormTarget) {
          $(this.formTarget).slideUp();
        }
        this.displayOutput(lookupResult);
        this.outputTarget.classList.remove('d-none');
      } catch (error) {
        this.handleError(error);
      } finally {
        // this.formTarget.classList.add('d-none');
      }
    }

    if (this.hasAutocompleteTarget) {
      this.autocompleteTarget.addEventListener('autocomplete.change', (e) => {
        const { vehicle, vehicleInfo } = e.detail.selected.dataset;
        const vehicleData = JSON.parse(vehicle);
        const vehicleInfoData = JSON.parse(vehicleInfo);
        this.emitResultRetrievedEvent({ vehicle: vehicleData, vehicle_info: vehicleInfoData });
        this.displayOutput({ vehicle: vehicleData, vehicle_info: vehicleInfoData });
        this.outputTarget.classList.remove('d-none');
        this.lookupFormTarget.classList.add('d-none');
        this.existingVehicleLookupTarget.classList.add('d-none');
      });
    }
  }

  getVehicleData(vrm, mileage) {
    return new Promise((resolve, reject) => {
      apiClient(`/api/vehicle/lookup?vrm=${vrm}&mileage=${mileage}`)
        .then((response) => {
          setTimeout(() => {
            this.emitResultRetrievedEvent(response);
            this.outputTarget.classList.remove('d-none');
            if (this.hasLookupFormTarget) {
              this.lookupFormTarget.classList.add('d-none');
            }
            if (this.hasFormTarget) {
              const formSubmitBtn = this.formTarget.querySelector('button');
              formSubmitBtn.disabled = true; // ! This is to prevent new form submission after line item form is filled out. Not sure why it even tries to submit it again, but this is a quick fix for now.
            }
            resolve(response);
          }, 200);
        })
        .catch((errorResponse) => {
          const { errors } = errorResponse;
          reject(errors);
        });
    });
  }

  emitResultRetrievedEvent(response) {
    const { vehicle, vehicle_info: vehicleInfo, valuation } = response;
    const customEvent = new CustomEvent('vehicle-lookup:resultRetrieved', {
      detail: { vehicle, vehicleInfo, valuation },
      bubbles: true,
    });
    this.element.dispatchEvent(customEvent);
  }

  validateForm() {
    let isValid = true;
    if (this.formTarget.checkValidity) {
      isValid = this.formTarget.checkValidity();
    } else {
      const vrmValid = this.vrmInputTarget.checkValidity();
      const mileageValid = this.mileageInputTarget.checkValidity();

      if (!vrmValid || !mileageValid) {
        isValid = false;
      }
    }
    this.formTarget.classList.add('was-validated');
    return isValid;
  }

  handleKeypress(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleSubmit();
    }
  }

  async handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    if (!this.validateForm()) {
      return;
    }

    this.loaderTarget.classList.remove('d-none');
    this.formTarget.classList.add('d-none');
    if (this.hasExistingVehicleLookupTarget) {
      this.existingVehicleLookupTarget.classList.add('d-none');
    }

    try {
      const vehicleData = await this.getVehicleData(
        this.vrmInputTarget.value,
        this.mileageInputTarget.value
      );
      this.loaderTarget.classList.add('d-none');
      this.displayOutput(vehicleData);
      this.outputTarget.classList.remove('d-none');
    } catch (error) {
      this.handleError(error);
    } finally {
      this.formTarget.classList.add('d-none');
    }
  }

  handleError(errors) {
    const customEvent = new CustomEvent('vehicle-lookup:error', {
      bubbles: true,
    });
    document.dispatchEvent(customEvent);

    this.errorTarget.querySelector('[data-message]').innerText =
      errors && errors[0] ? errors[0].title : 'Something went wrong. Pleast try again.';
    this.loaderTarget.classList.add('d-none');
    this.errorTarget.classList.remove('d-none');
    this.formTarget.classList.add('d-none');
    this.retryButtonTarget.classList.remove('d-none');
  }

  generateVehicleInfoText(data) {
    const { vehicle, vehicle_info: vehicleInfo } = data;
    const {
      color,
      engine_capacity: engineCapacity,
      fuel,
      year_of_manufacture: yearOfManufacture,
      transmission,
    } = vehicle;

    let vehicleInfoText = '';

    if (vehicleInfo.mileage) {
      vehicleInfoText += `<span>${parseFloat(vehicleInfo.mileage).toLocaleString()} miles</span>`;
    }
    if (yearOfManufacture) {
      // year sometimes comes back as date, e.g: 2016-01-01
      if (yearOfManufacture.includes('-')) {
        const [year] = yearOfManufacture.split('-');
        vehicleInfoText += `<span>${year}</span>`;
      } else {
        vehicleInfoText += `<span>${yearOfManufacture}</span>`;
      }
    }
    if (color) {
      vehicleInfoText += `<span>${color}</span>`;
    }

    const engineInfo = [];
    const isElectric = fuel && fuel === 'Electric';

    if (!isElectric) {
      const vehicleEngineCapacity = `${(parseFloat(engineCapacity) / 1000).toFixed(1)}`;
      engineInfo.push(`${vehicleEngineCapacity}-litre`);
    }

    if (fuel) {
      engineInfo.push(isElectric ? 'Electric' : fuel.toLowerCase());
    }

    if (engineInfo.length) {
      vehicleInfoText += `<span>${engineInfo.join(' ')}</span>`;
    }

    if (transmission) {
      vehicleInfoText += `<span>${transmission}</span>`;
    }

    return vehicleInfoText;
  }

  generateWarnings(messages) {
    let warningsHTML = '';

    messages.forEach(({ title, message }, i) => {
      const warningLi = `
        <li class="${i < messages.length - 1 ? 'mb-1' : ''}">
          <span class="d-inline-block small fw-bold text-warning">
            <i class="fa-light fa-triangle-exclamation me-1"></i> ${message ?? title}
          </span>
        </li>`;
      warningsHTML += warningLi;
    });

    return warningsHTML;
  }

  displayOutput(data) {
    const { vehicle, warnings } = data;
    const { make, model, vrm } = vehicle;
    this.vrmOutputTarget.innerHTML = convertReg(vrm);
    if (this.hasMakeLogoOutputTarget) {
      this.makeLogoOutputTarget.setAttribute('src', window.getMakeLogo(make));
    }

    this.makeModelOutputTarget.innerHTML = `${make} ${model}`;
    this.descriptionOutputTarget.innerHTML = this.generateVehicleInfoText(data);

    if (warnings && warnings.length > 0 && this.hasWarningsContainerTarget) {
      this.warningsContainerTarget.innerHTML = this.generateWarnings(warnings);
      this.warningsContainerTarget.classList.remove('d-none');
    }

    // if (this.hasFormTarget) {
    //   $(this.formTarget).slideUp(() => {
    //     $(this.outputTarget).slideDown();
    //     this.dispatch('displayOutput');
    //   });
    // }
  }

  resetForm() {
    this.formTarget.classList.remove('was-validated');
    this.vrmInputTarget.value = '';
    this.mileageInputTarget.value = '';
  }

  retry() {
    this.resetForm();
    this.errorTarget.classList.add('d-none');
    this.formTarget.classList.remove('d-none');
    if (this.hasExistingVehicleLookupTarget) {
      this.existingVehicleLookupTarget.classList.remove('d-none');
    }
    this.retryButtonTarget.classList.add('d-none');
  }
}
