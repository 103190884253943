import $ from 'jquery';
import { isValid as isPostcodeValid } from 'postcode';
import { isDebugMode } from './util';

export class FormValidator {
  static validateBasicFields = (wrapperEl) => {
    let basicFieldsValid = true;
    [...wrapperEl.querySelectorAll('[required]')].forEach((field) => {
      const validationWrapper = field.closest('[data-validation-wrapper]');
      const fieldValid = field.checkValidity();
      if (!fieldValid) {
        basicFieldsValid = false;
        if (validationWrapper) {
          validationWrapper.classList.toggle('was-validated', !fieldValid);
        }
      }
    });

    return basicFieldsValid;
  };

  static validateClientSideValidationFields = (wrapperEl, validators) => {
    $(wrapperEl).enableClientSideValidations();
    const clientSideValidationPassing = $(wrapperEl).isValid(validators);
    return clientSideValidationPassing;
  };

  static validateFormCheckPanels = (wrapperEl) => {
    const formCheckPanelsWrappers = wrapperEl.querySelectorAll(
      '[data-form-check-panels-wrapper][data-required]'
    );
    let allFormCheckPanelsSelected = true;
    if (formCheckPanelsWrappers.length) {
      [...formCheckPanelsWrappers].forEach((formCheckPanelWrapper) => {
        const minRequired = parseInt(formCheckPanelWrapper.dataset.minimumRequired || 1, 10);
        let selectedInputsCount = 0;
        const formCheckPanelInputs = formCheckPanelWrapper.querySelectorAll('input');
        [...formCheckPanelInputs].forEach((input) => {
          if (input.checked) {
            selectedInputsCount += 1;
          }
        });
        const isFormCheckPanelValid = selectedInputsCount >= minRequired;

        if (!isFormCheckPanelValid) {
          allFormCheckPanelsSelected = false;
          formCheckPanelWrapper.classList.add('is-invalid');
        }
      });
    }

    return allFormCheckPanelsSelected;
  };

  static validateOtherFields = (wrapperEl) => {
    let otherFieldsValid = true;
    [...wrapperEl.querySelectorAll('.js-postcode-input')].forEach((field) => {
      const postcodeIsValid = isPostcodeValid(field.value);
      if (!postcodeIsValid) {
        otherFieldsValid = false;
        field.classList.add('is-invalid');
      }
    });

    return otherFieldsValid;
  };

  static validate = (wrapperEl, validators = null) => {
    if (isDebugMode()) {
      return true;
    }

    const formValidator = new FormValidator();

    const basicFieldsValid = formValidator.constructor.validateBasicFields(wrapperEl);

    const closestClientSideValidationWrapper = wrapperEl.closest('[data-client-side-validations]');
    let clientSideValidationPassing = true;
    if (closestClientSideValidationWrapper) {
      clientSideValidationPassing = formValidator.constructor.validateClientSideValidationFields(
        closestClientSideValidationWrapper,
        validators
      );
    }
    const formCheckPanelsValid = formValidator.constructor.validateFormCheckPanels(wrapperEl);

    const otherFieldsValid = formValidator.constructor.validateOtherFields(wrapperEl);

    return basicFieldsValid && clientSideValidationPassing && formCheckPanelsValid && otherFieldsValid;
  };

  static enableBootstrapValidation = (form) => {
    form.addEventListener(
      'submit',
      (event) => {
        if (!event.target.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }

        event.target.classList.add('was-validated');
      },
      false
    );
  };
}
