import { Controller } from '@hotwired/stimulus';
import { convertToCurrency } from '../../shared/util';

export default class extends Controller {
  static targets = ['vehicleInfoIdField', 'price', 'adjustedPrice'];

  connect() {
    this.valuationBase = this.element.dataset.valuationBase;
    this.valuationAdjustmentPercentage = parseFloat(
      this.element.dataset.valuationAdjustmentPercentage || 0
    );
    this.valuationMultiplier = this.valuationAdjustmentPercentage / 100;

    document.addEventListener('vehicle-lookup:resultRetrieved', (e) => {
      const { valuation, vehicleInfo } = e.detail;

      if (this.hasVehicleInfoIdFieldTarget) {
        const { id } = vehicleInfo;

        if (id) {
          this.vehicleInfoIdFieldTargets.forEach((vehicleInfoIdField) => {
            vehicleInfoIdField.value = id;
          });
        }
      }

      if (this.hasAdjustedPriceTarget) {
        let adjustedPrice = null;
        if (valuation) {
          const basePrice = valuation[this.valuationBase];
          adjustedPrice = basePrice + basePrice * this.valuationMultiplier;
        }
        this.adjustedPriceTarget.innerText = adjustedPrice ? convertToCurrency(adjustedPrice) : '–';
      }

      this.priceTargets.forEach((priceTarget) => {
        const { valuationResult } = priceTarget.dataset;
        priceTarget.innerText =
          valuation && valuation[valuationResult] ? convertToCurrency(valuation[valuationResult]) : '–';
      });
    });
  }
}
