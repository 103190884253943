import $ from 'jquery';
// import DirtyForm from 'dirty-form';
import Cleave from 'cleave.js';
import { parse as parsePostcode } from 'postcode';
import { FormValidator } from './form-validator';
import { disableButton } from './util';

require('cleave.js/dist/addons/cleave-phone.gb');

// window.jQuery = $;

export class Form {
  constructor(form) {
    this.form = form;

    // TO-DO – Frontend: Kurtis wanted it changing from simple alert to a modal. Most likely Turbo needed for that. Come back to it when we have Turbo implemented - Seb
    if (this.form.classList.contains('js-dirty-form')) {
      // const dirtyForm = new DirtyForm(this.form);
    }

    if (this.form.hasAttribute('data-client-side-validations')) {
      this.initClientSideValidation();
      const submitBtn = this.form.querySelector('[data-form-submit-btn]');
      if (submitBtn) {
        submitBtn.addEventListener('click', (e) => {
          const isValid = FormValidator.validate(this.form, this.validators);
          if (!isValid) {
            e.preventDefault();

            const firstError = this.form.querySelector('.invalid-feedback:not(.d-none)');
            if (firstError) {
              firstError.scrollIntoView({ block: 'center' });
            }

            return false;
          }

          if (submitBtn) {
            disableButton(submitBtn);
          }
          this.form.submit();
          return true;
        });
      }
    }

    if (this.form.classList.contains('js-needs-bs-validation')) {
      FormValidator.enableBootstrapValidation(this.form);
    }

    const phoneNumberFields = this.form.querySelectorAll('.js-phone-number-input');
    if (phoneNumberFields.length) {
      phoneNumberFields.forEach((phoneNumberField) => {
        const cleavePhoneNumberField = new Cleave(phoneNumberField, {
          phone: true,
          phoneRegionCode: 'GB',
        });
      });
    }

    const timeFields = this.form.querySelectorAll('.js-time-input');
    if (timeFields.length) {
      timeFields.forEach((timeField) => {
        const cleaveTimeField = new Cleave(timeField, {
          time: true,
          timePattern: ['h', 'm'],
        });
      });
    }

    const mileageFields = this.form.querySelectorAll('.js-mileage-input');
    if (mileageFields.length) {
      mileageFields.forEach((mileageField) => {
        mileageField.addEventListener('keydown', (e) => {
          if (e.key === '.') {
            e.preventDefault();
            return false;
          }
          // Makes sure that mileage field is never negative
          const mileage = e.target.value;
          const newValue = !!mileage && Math.abs(mileage) >= 0 ? Math.abs(mileage) : null;
          e.target.value = newValue;
        });
      });
    }

    const regPlateFields = this.form.querySelectorAll('.js-reg-plate-input');
    if (regPlateFields.length) {
      regPlateFields.forEach((regPlateField) => {
        regPlateField.addEventListener('input', (e) => {
          // Prevent non-alphanumeric characters for reg plate
          const regPlate = e.target.value ? e.target.value.replace(/[^0-9a-zA-Z ]+/gi, '') : '';
          e.target.value = regPlate;
        });
      });
    }

    const postcodeFields = this.form.querySelectorAll('.js-postcode-input');
    if (postcodeFields.length) {
      postcodeFields.forEach((field) => {
        field.addEventListener('change', ({ target }) => {
          const { postcode, valid } = parsePostcode(target.value);
          if (postcode && valid) {
            target.value = postcode;
          }
        });
      });
    }

    this.setupEventListeners();
  }

  setupEventListeners() {
    $('[data-form-check-panels-wrapper] input').on('change', function () {
      $(this).closest('[data-form-check-panels-wrapper]').removeClass('is-invalid');
    });
  }

  initClientSideValidation() {
    setTimeout(() => {
      $(this.form).disableClientSideValidations();
    }, 200);
    setTimeout(() => {
      this.validators = this.form.ClientSideValidations.settings.validators;
    }, 500);
  }
}
