import { Controller } from '@hotwired/stimulus';
import Dropzone from 'dropzone';

import { DirectUploadController } from './direct-upload';
import { getMetaValue, removeElement } from '../../shared/util';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    this.dropzone = this._createDropzone();
    this._hideFileInput();
    this._bindEvents();
  }

  _createDropzone() {
    this.MAX_FINAL_WIDTH = 1600;
    this.MAX_FINAL_HEIGHT = 900;

    const dropzoneConfig = {
      url: this.url,
      headers: this.headers,
      maxFiles: this.maxFiles,
      maxFilesize: this.maxFileSize,
      acceptedFiles: this.acceptedFiles,
      addRemoveLinks: false,
      autoQueue: false,
      previewsContainer: '.dropzone-previews',
      previewTemplate: document.querySelector('[data-dz-preview-template]').innerHTML,
      // https://github.com/dropzone/dropzone/blob/main/src/options.js#L572
      // https://github.com/dropzone/dropzone/blob/main/src/preview-template.html
    };

    return new Dropzone(this.element, dropzoneConfig);
  }

  _hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = 'none';
  }

  _bindEvents() {
    this.dropzone.on('addedfile', (originalImage) => {
      this.dropzone.on('thumbnail', (thumbnail) => {
        if (originalImage.upload.uuid === thumbnail.upload.uuid) {
          setTimeout(async () => {
            if (originalImage.accepted) {
              const resizedImage = await this.getResizedImage(originalImage);
              const directUploadController = new DirectUploadController(this, resizedImage);
              directUploadController.start();
            }
          }, 0);
        }
      });
    });

    this.dropzone.on('addedfile', (file) => {
      this.dropzone.element.classList.add('dz-started');
    });

    this.dropzone.on('removedfile', (file) => {
      if (file.controller) {
        removeElement(file.controller.hiddenInput);
      }
    });

    this.dropzone.on('canceled', (file) => {
      if (file.controller) {
        file.controller.xhr.abort();
      }
    });
  }

  getFinalImageDimensions(width, height) {
    let finalWidth = width;
    let finalHeight = height;

    if (width > height) {
      if (width > this.MAX_FINAL_WIDTH) {
        finalHeight *= this.MAX_FINAL_WIDTH / finalWidth;
        finalWidth = this.MAX_FINAL_WIDTH;
      }
    } else if (height > this.MAX_FINAL_HEIGHT) {
      finalWidth *= this.MAX_FINAL_HEIGHT / finalHeight;
      finalHeight = this.MAX_FINAL_HEIGHT;
    }

    return { finalWidth, finalHeight };
  }

  getResizedImage(originalImage) {
    return new Promise((resolve) => {
      const {
        width = this.MAX_FINAL_WIDTH,
        height = this.MAX_FINAL_HEIGHT,
        name,
        type,
        dataURL,
      } = originalImage;

      if (width < this.MAX_FINAL_WIDTH && height < this.MAX_FINAL_HEIGHT) {
        resolve(originalImage);
      }

      const { finalWidth, finalHeight } = this.getFinalImageDimensions(width, height);
      const canvas = document.createElement('canvas');
      canvas.width = finalWidth;
      canvas.height = finalHeight;
      const ctx = canvas.getContext('2d');
      // use white background for images with transparent background
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      const tempImg = new Image();
      tempImg.src = dataURL;
      ctx.drawImage(tempImg, 0, 0, finalWidth, finalHeight);

      canvas.toBlob(
        (blob) => {
          const resizedImage = new File([blob], name, { type });
          const origProps = ['upload', 'status', 'previewElement', 'previewTemplate', 'accepted'];
          origProps.forEach((prop) => {
            resizedImage[prop] = originalImage[prop];
          });

          resolve(resizedImage);
        },
        'image/jpeg',
        0.7
      );
    });
  }

  get url() {
    return this.inputTarget.dataset.directUploadUrl;
  }

  get headers() {
    return { 'X-CSRF-Token': getMetaValue('csrf-token') };
  }

  get maxFiles() {
    return parseInt(this.element.dataset.maxFiles, 10) || 10;
  }

  get maxFileSize() {
    return parseInt(this.element.dataset.maxFileSize, 10) || 256;
  }

  get acceptedFiles() {
    return this.element.dataset.acceptedFiles || null;
  }

  get addRemoveLinks() {
    return this.element.dataset.addRemoveLinks || true;
  }
}
